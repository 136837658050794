<template>
  <div class="">
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">Settings</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right" v-if="isFeatureActive">
          <button
            @click="newPlanView = true"
            class="btn btn-primary btn-site mt-2"
          >
            Add New Plan
          </button>
          <button
            @click="genreView = true"
            class="btn btn-primary btn-site mt-2"
          >
            Add Genre
          </button>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Settings data loading..." />
    <div v-else>
      <div class="card siteCard mt-3">
        <div class="p-3">
          <vs-tabs>
            <vs-tab label="Plans">
              <div class="table-responsive py-2">
                <vs-table
                  id="div-with-loading"
                  max-items="10"
                  stripe
                  :data="plans"
                >
                  <template slot="thead">
                    <vs-th class="text-dark" scope="col">S/N</vs-th>
                    <vs-th class="text-dark" scope="col">Date</vs-th>
                    <vs-th class="text-dark" scope="col">Plan name</vs-th>
                    <vs-th class="text-dark" scope="col">Amount</vs-th>
                    <vs-th class="text-dark" scope="col">Price Deduction</vs-th>
                    <vs-th class="text-dark" scope="col">Perks</vs-th>
                    <vs-th class="text-dark" scope="col" v-if="isFeatureActive"
                      >Action</vs-th
                    >
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(plan, indextr) in data">
                      <td>
                        <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                      </td>

                      <td>
                        <p class="text-dark mb-0 small">
                          {{
                            moment
                              .utc(new Date(plan.created_at))
                              .format("Do MMM YYYY")
                          }}
                        </p>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ plan.name | capitalize }}
                          </p>
                          <p class="small">
                            {{ plan.for | uppercase }}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ plan.amount_monthly | currency("₦", 0) }}/month
                          </p>
                          <p class="text-dark mb-0 title">
                            {{ plan.amount_yearly | currency("₦", 0) }}/year
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ plan.price_deduction }}%
                          </p>
                        </div>
                      </td>
                      <td style="width: 22%">
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 small">
                            {{ plan.perks.length }} perks
                          </p>
                          <p class="text-dark small">
                            <span
                              v-for="(perk, index) in plan.perks"
                              :key="index"
                            >
                              <span> {{ perk }}</span>
                              <span v-if="index < plan.perks.length - 1"
                                >,</span
                              >
                            </span>
                          </p>
                        </div>
                      </td>

                      <td v-if="isFeatureActive">
                        <button
                          @click="updatePlan(plan)"
                          class="btn btn-primary btn-sm btn-site mt-2"
                        >
                          Update Plan
                        </button>

                        <button
                          @click="removePlan(plan)"
                          class="btn btn-outline-primary btn-sm mt-2"
                        >
                          Remove Plan
                        </button>
                      </td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vs-tab>

            <vs-tab label="Rates">
              <div class="table-responsive py-2">
                <vs-table
                  id="div-with-loading"
                  max-items="10"
                  stripe
                  :data="rates"
                >
                  <template slot="thead">
                    <vs-th class="text-dark" scope="col">S/N</vs-th>
                    <vs-th class="text-dark" scope="col">Date</vs-th>
                    <vs-th class="text-dark" scope="col">Pre Currency</vs-th>
                    <vs-th class="text-dark" scope="col">Currency</vs-th>
                    <vs-th class="text-dark" scope="col">Rate</vs-th>
                    <vs-th class="text-dark" scope="col" v-if="isFeatureActive"
                      >Action</vs-th
                    >
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(rate, indextr) in data">
                      <td>
                        <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                      </td>

                      <td>
                        <p class="text-dark mb-0 small">
                          {{
                            moment
                              .utc(new Date(rate.created_at))
                              .format("Do MMM YYYY")
                          }}
                        </p>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ rate.precurrency || "-" | capitalize }}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ rate.currency | capitalize }}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ rate.rate }}
                          </p>
                        </div>
                      </td>

                      <td>
                        <button
                          v-if="isFeatureActive"
                          @click="updateRate(rate)"
                          class="btn btn-primary btn-sm btn-site mt-2"
                        >
                          Update price
                        </button>
                      </td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vs-tab>

            <vs-tab label="Genres">
              <div class="table-responsive py-2">
                <vs-table
                  id="div-with-loading"
                  max-items="10"
                  stripe
                  :data="genres"
                >
                  <template slot="thead">
                    <vs-th class="text-dark" scope="col">S/N</vs-th>
                    <vs-th class="text-dark" scope="col">Genre</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(genre, indextr) in data">
                      <td>
                        <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                      </td>

                      <td>
                        <div class="font-weight-bold">
                          <p class="text-dark mb-0 title">
                            {{ genre.name || "-" | capitalize }}
                          </p>
                        </div>
                      </td>

                      <td>
                        <button
                          @click="removeGenre(genre)"
                          class="btn btn-primary btn-sm btn-site mt-2"
                        >
                          Remove genre
                        </button>
                      </td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
    </div>

    <vs-popup class="rateModal" title="Add New Rate" :active.sync="rateView">
      <form class="p-4" @submit.prevent="submitForm" role="form">
        <div class="form-group select-input">
          <label class="text-dark" to="email">Currency From</label>
          <vs-select
            autocomplete
            class="selectExample"
            v-model="rateData.precurrency"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in allCurrencies"
            />
          </vs-select>
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="email">Currency To</label>
          <vs-select
            autocomplete
            class="selectExample"
            v-model="rateData.currency"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in allCurrencies"
            />
          </vs-select>
        </div>

        <div class="form-group">
          <label class="text-dark" to="rate">Rate</label>

          <!-- float input type -->

          <input
            v-model="rateData.rate"
            step="0.01"
            min="0"
            required
            type="number"
            name="rate"
            id="rate"
            class="form-control"
          />
        </div>

        <div class="form-group text-center mt-5">
          <button
            :disabled="btnLoading"
            type="submit"
            class="btn btn-primary btn-block btn-site px-5"
          >
            Add Rate
            <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="rateModal" title="Update Rate" :active.sync="updateView">
      <form class="p-4" @submit.prevent="submitFormUpdate" role="form">
        <div class="form-group select-input">
          <label class="text-dark" to="email">Currency From</label>
          <vs-select
            autocomplete
            class="selectExample"
            disabled
            v-model="updateData.precurrency"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in allCurrencies"
            />
          </vs-select>
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="email">Currency To</label>
          <vs-select
            autocomplete
            class="selectExample"
            disabled
            v-model="updateData.currency"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in allCurrencies"
            />
          </vs-select>
        </div>

        <div class="form-group">
          <label class="text-dark" to="rate">Rate</label>

          <input
            v-model="updateData.rate"
            step="0.01"
            min="0"
            required
            type="number"
            name="rate"
            id="rate"
            class="form-control"
          />
        </div>

        <div class="form-group text-center mt-5">
          <button
            :disabled="btnLoading"
            type="submit"
            class="btn btn-primary btn-block btn-site px-5"
          >
            Update Rate
            <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup class="rateModal" title="Update Plan" :active.sync="planView">
      <form class="p-4" @submit.prevent="submitFormUpdatePlan" role="form">
        <div class="form-group select-input">
          <label class="text-dark" to="planName">Plan name</label>
          <input
            v-model="planData.name"
            required
            name="planName"
            id="planName"
            class="form-control"
          />
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="planName">Plan for</label>
          <input
            v-model="planData.for"
            required
            name="planFor"
            id="planFor"
            class="form-control"
          />
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="planName">Plan Description</label>
          <input v-model="planData.description" required class="form-control" />
        </div>

        <div class="form-group">
          <label class="text-dark">Amount per month (₦)</label>
          <input
            v-model="planData.amount_monthly"
            required
            step="0.01"
            min="0"
            type="number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label class="text-dark">Amount per year (₦)</label>
          <input
            v-model="planData.amount_yearly"
            required
            step="0.01"
            min="0"
            type="number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label class="text-dark">Percentage Deduction</label>
          <input
            v-model="planData.price_deduction"
            required
            min="0"
            type="number"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label class="text-dark">Perks</label>
          <vue-tags-input
            class="form-tags"
            v-model="perksUpdate"
            placeholder="Add Perks"
            :tags="perksActive"
            @tags-changed="(newPerks) => (perksActive = newPerks)"
          />
        </div>

        <div class="form-group text-center mt-5">
          <button
            :disabled="btnPlanLoading"
            type="submit"
            class="btn btn-primary btn-block btn-site px-5"
          >
            Update Plan
            <BtnLoading
              v-if="btnPlanLoading"
              class="d-inline-block"
              height="18"
            />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup
      class="rateModal"
      title="Create new plan"
      :active.sync="newPlanView"
    >
      <form class="p-4" @submit.prevent="submitFormNewPlan" role="form">
        <div class="form-group select-input">
          <label class="text-dark" to="planName">Plan name</label>
          <input
            v-model="newPlanData.name"
            required
            name="planName"
            id="planName"
            class="form-control"
          />
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="planFor">Plan for</label>
          <input
            v-model="newPlanData.for"
            required
            name="planFor"
            id="planFor"
            class="form-control"
          />
        </div>

        <div class="form-group select-input">
          <label class="text-dark" to="planDecr">Plan Description</label>
          <input
            v-model="newPlanData.description"
            required
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label class="text-dark">Amount per month (₦)</label>
          <input
            v-model="newPlanData.amount_monthly"
            required
            step="0.01"
            min="0"
            type="number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label class="text-dark">Amount per year (₦)</label>
          <input
            v-model="newPlanData.amount_yearly"
            required
            step="0.01"
            min="0"
            type="number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label class="text-dark">Percentage Deduction</label>
          <input
            v-model="newPlanData.price_deduction"
            required
            min="0"
            type="number"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label class="text-dark">Perks</label>
          <vue-tags-input
            class="form-tags"
            v-model="perksNew"
            placeholder="Add Perks"
            :tags="perksNewActive"
            @tags-changed="(newPerks) => (perksNewActive = newPerks)"
          />
        </div>

        <div class="form-group text-center mt-5">
          <button
            :disabled="btnPlanLoading"
            type="submit"
            class="btn btn-primary btn-block btn-site px-5"
          >
            Create new plan
            <BtnLoading
              v-if="btnPlanLoading"
              class="d-inline-block"
              height="18"
            />
          </button>
        </div>
      </form>
    </vs-popup>

    <vs-popup
      class="rateModal"
      title="Add Genre"
      :active.sync="genreView"
    >
      <form class="p-4" @submit.prevent="submitFormGenre" role="form">
        <div class="form-group select-input">
          <label class="text-dark" to="genreName">Genre name</label>
          <input
            v-model="newGenre.name"
            required
            name="genreName"
            id="genreName"
            class="form-control"
          />
        </div>

        <div class="form-group text-center mt-5">
          <button
            :disabled="btnGenreLoading"
            type="submit"
            class="btn btn-primary btn-block btn-site px-5"
          >
            Add new genre
            <BtnLoading
              v-if="btnGenreLoading"
              class="d-inline-block"
              height="18"
            />
          </button>
        </div>
      </form>
    </vs-popup>

    
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading";
import BtnLoading from "../../../components/BtnLoading";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "Home",
  components: {
    SectionLoading,
    BtnLoading,
    VueTagsInput,
  },
  data() {
    return {
      perksUpdate: "",
      perksNew: "",
      perksActive: [],
      perksNewActive: [],
      loading: false,
      rateView: false,
      newPlanView: false,
      btnLoading: false,
      updateView: false,
      btnPlanLoading: false,
      updateData: {
        precurrency: "",
        currency: "",
        rate: "",
      },
      planView: false,
      planData: {},
      newPlanData: {},
      rateData: {
        precurrency: "",
        currency: "",
        rate: "",
      },
      allCurrencies: [
        {
          text: "Euro",
          value: "euro",
        },
        {
          text: "US Dollar",
          value: "usd",
        },
        {
          text: "British Pound",
          value: "gbp",
        },
        {
          text: "Nigerian Naira",
          value: "ngn",
        },
      ],
      rates: [],
      plans: [],
      genreView: false,
      genres: [],
      btnGenreLoading: false,
      newGenre: {},
      genreData: {},
    };
  },
  mounted() {
    this.getPlans();
    this.getRatings();
    this.getGenres()
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
    isFeatureActive() {
      return this.user.user_type === "super-admin";
    },
  },
  methods: {
    submitFormNewPlan() {
      let payload = this.newPlanData;
      payload.perks = this.perksNewActive.map((perk) => perk.text);

      let fetch = {
        path: `admin/subscription/store`,
        data: payload,
      };

      this.btnPlanLoading = true;
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Plan created successfully",
            "Success",
            this.$toastPosition
          );
          this.btnPlanLoading = false;
          this.planView = false;
          this.newPlanView = false;
          this.updateView = false;
          this.getPlans();
        })
        .catch((err) => {
          this.btnPlanLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to create new plan",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while creating plan",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    submitFormUpdatePlan() {
      let payload = this.planData;
      payload.perks = this.perksActive.map((perk) => perk.text);

      let fetch = {
        path: `admin/subscription/update/${payload.uuid}`,
        data: payload,
      };
      this.btnPlanLoading = true;
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Plan updated successfully",
            "Success",
            this.$toastPosition
          );
          this.btnPlanLoading = false;
          this.planView = false;
          this.updateView = false;
          this.getPlans();
        })
        .catch((err) => {
          this.btnPlanLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to update plan",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while updating plan",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    submitFormUpdate() {
      let fetch = {
        path: `admin/rates/update-rate/${this.updateData.id}`,
        data: this.updateData,
      };
      this.btnLoading = true;
      this.$store
        .dispatch("putRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Rate updated successfully",
            "Success",
            this.$toastPosition
          );
          this.btnLoading = false;
          this.rateView = false;
          this.updateView = false;
          this.getRatings();
        })
        .catch((err) => {
          this.btnLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to update rate",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while updating rate",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    submitForm() {
      let fetch = {
        path: `admin/rates/create`,
        data: this.rateData,
      };
      this.btnLoading = true;
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "New Rate created successfully",
            "Success",
            this.$toastPosition
          );
          this.btnLoading = false;
          this.rateView = false;
          this.getRatings();
        })
        .catch((err) => {
          this.btnLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to create rate",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while creating new rate",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    getPlans() {
      let fetch = {
        path: `/admin/subscription`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          this.plans = data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Plans",
              text: "Unable to get Plans",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    getRatings() {
      let fetch = {
        path: `/admin/rates`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          this.rates = data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Ratings",
              text: "Unable to get Ratings",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    updateRate(rate) {
      this.updateView = true;
      this.updateData = rate;
    },
    removePlan(plan) {
      this.planData = plan;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `Are you sure you want to remove this genre ?`,
        accept: this.removeConfirmed,
      });
    },
    removeGenre(genre){
      this.genreData = genre;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `Are you sure you want to remove this genre ?`,
        accept: this.removeConfirmedGenre,
      });
    },
    removeConfirmed() {
      this.$vs.loading();
      let fetch = {
        path: `admin/subscription/delete/${this.planData.uuid}`,
        data: {},
      };
      this.$store
        .dispatch("deleteRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Plan Delete",
            "Plan Deleted Successfully",
            this.$toastPosition
          );

          this.$vs.loading.close();
          this.getPlans();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to delete plan",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to delete plan",
              this.$toastPosition
            );
          }
        });
    },
    removeConfirmedGenre() {
      this.$vs.loading();
      let fetch = {
        path: `admin/genre/${this.genreData.id}`,
        data: {},
      };
      this.$store
        .dispatch("deleteRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Genre Delete",
            "Genre Deleted Successfully",
            this.$toastPosition
          );

          this.$vs.loading.close();
          this.getGenres();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to delete genre",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to genre plan",
              this.$toastPosition
            );
          }
        });
    },
    updatePlan(plan) {
      this.planView = true;
      this.planData = plan;
      this.perksActive = plan.perks.map((perk) => {
        return {
          text: perk,
          value: perk,
        };
      });
    },
    submitFormGenre() {
      let fetch = {
        path: `admin/genre/store`,
        data: this.newGenre,
      };

      this.btnGenreLoading = true;
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Genre added successfully",
            "Success",
            this.$toastPosition
          );
          this.btnGenreLoading = false;
          this.planView = false;
          this.newPlanView = false;
          this.updateView = false;
          this.genreView = false;
          this.getGenres();
        })
        .catch((err) => {
          this.btnGenreLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to add new genre",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while adding genre",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
    getGenres() {
      let fetch = {
        path: `/admin/genre`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          this.genres = data;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Ratings",
              text: "Unable to get Genres",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
  },
};
</script>
<style lang="scss">
.select-input {
  .con-select {
    width: 100%;
  }
}
.form-tags {
  max-width: 100% !important;
  .ti-input {
    display: block;
    width: 100%;
    padding: 0.6rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    box-shadow: none;
    .ti-tag {
      background-color: #f88f7f;
    }
  }
}
</style>
